//import { Link } from "react-router-dom";
import './home.css';


const Home = () => {
    return (
        <div className="home">
            <div className="profile-picture">
                <img src="/sugaridk.png" alt="sugaridk" />
            </div>
            <div className="profile-name">
                <h1>SugarIDK</h1>
            </div>
            <div className="profile-description">
                <p>Hi I'm Sugar! I'm an aspiring VTuber who enjoys coding websites, bots, and more.</p>
                <ul className="container">
                    <li id="skill-list-title">
                        Skills
                    </li>
                    <li>
                        <h2>Pro</h2>
                        <ul>
                            <li>HTML</li>
                            <li>CSS</li>
                            <li>JavaScript</li>
                            <li>Node.JS</li>
                            <li>Discord.JS</li>
                            <li>Bun</li>
                            <li>React</li>
                            <li>Luascript</li>
                            <li>(No)SQL</li>
                            <li>Express</li>
                        </ul>
                    </li>
                    <li>
                        <h2>Good</h2>
                        <ul>
                            <li>Python</li>
                            <li>Vue</li>
                        </ul>
                    </li>
                    <li>
                        <h2>Learning</h2>
                        <ul>
                            <li>C++</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Home;